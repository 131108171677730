import Swiper from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';

const heroSliderClass = '.js-hero-slider';
const heroSliderTextClass = '.hero-slider__text-container';
const heroSliderTextContainers = document.querySelectorAll(heroSliderTextClass);

// Swiper init
const heroSlider = new Swiper(heroSliderClass, {
    modules: [Pagination, Autoplay],
    slidesPerView: 1,
    loop: true,
    autoplay: {
        delay: 6000,
    },
    pagination: {
        el: '.js-hero-slider-pagination',
        clickable: true,
        bulletClass: 'hero-slider__pagination-item',
        bulletActiveClass: 'is-active',
    },
    on: {
        slideChange: () => {
            if (!isMobileDevice()) {
                updateSlideAnimation();
            }
        },
    },
});

function isMobileDevice() {
    return (
        window.innerWidth <= 768 ||
        /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    );
}

function updateSlideAnimation() {
    let currentIndex = heroSlider.realIndex;
    heroSliderTextContainers.forEach((container, index) => {
        if (index === currentIndex) {
            container.classList.add('aos-animate');
        } else {
            container.classList.remove('aos-animate');
        }
    });
}
